/**
 * Created by stapletont on 23/11/2015.
 */

define('utils', function() {

  /**
   * Public Utility methods
   */
  var utils = {

    /**
     * Merge one or more objects
     * @returns {Object}
     */
    extend: function() {
      var merged = arguments[0];

      // Loop all but the first object's keys
      for (var i = 1; i < arguments.length; i++) {
        for (var key in arguments[i]) {

          // Check this isn't an external property
          if (arguments[i].hasOwnProperty(key)) {
            merged[key] = arguments[i][key];
          }
        }
      }

      // Return first argument, complete with new properties
      return merged || {};
    },

    /**
     * Get a property from component markup
     * @param propertyName
     * @param componentName
     *
     * Store your properties in the markup of your component
     * e.g.
     *
     * <span data-elysium-property-name="week" data-elysium-property-value="<elysium:Property property="trainerLindseyWorkoutVideo.week.text"/>"></span>
     *
     */

    getProperty: function(propertyName, componentName) {
      var propElement = document.querySelector('[data-component=' + componentName + '] [data-elysium-property-name=' + propertyName + ']');
      if (propElement && propElement.dataset.elysiumPropertyValue) return propElement.dataset.elysiumPropertyValue;
    }
  };

  return utils;
});
